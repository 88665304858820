<template>
  <button
    type="button"
    class="icon-btn"
    :class="[classes, { 'icon-btn--shadow': hasShadow }]"
    :aria-label="label">
    <ImagesSvg :width="iconWidth" :height="iconHeight" :icon="icon" />
    <p v-if="labelVisible" class="icon-btn__text">{{ label }}</p>
  </button>
</template>

<script lang="ts" setup>
import type { Icon } from '@/types/icon';
import type { Color } from '@/types/color';

export interface IconButtonProps {
  icon: Icon;
  label?: string;
  labelVisible?: boolean;
  iconWidth?: number;
  iconHeight?: number;
  color?: Color;
  hasBackground?: boolean;
  hasShadow?: boolean;
  asLink?: boolean;
}

const props = withDefaults(defineProps<IconButtonProps>(), {
  iconWidth: 24,
  iconHeight: 24,
  color: undefined,
  label: undefined,
  labelVisible: false,
  hasBackground: false,
  hasShadow: false,
  asLink: false,
});

const classes = computed(() => {
  const classes: string[] = [];
  if (props.hasBackground) {
    classes.push('icon-btn--solid');
  }

  if (props.color) {
    classes.push(`icon-btn--${props.color}`);
  }

  return classes.join(' ');
});
</script>

<style lang="scss" scoped>
.icon-btn {
  @include icon-button;
  color: currentColor;

  &--orange {
    color: $c-orange-1;
  }

  &--white {
    color: $c-white;
  }

  &--black {
    color: $c-black;
  }

  &--green {
    color: $c-green-3;
  }

  &--solid {
    background-color: $c-white;
  }

  &--shadow {
    box-shadow: 0 px(1) px(1) rgba(0, 104, 75, 0.25);
  }
}
</style>
